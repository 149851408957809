/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  SET_PORTFOLIO_NAME,
  SET_PORTFOLIO_ID
} from "src/actions/portfolioActions";

const isProduction = window.location.host === "api.nisidata.app";

const initialState = {
  // @FIXME: improve the way to get this
  name: isProduction ? "superbid" : "versatus",
  id: isProduction ? 1 : 10,
  // A gente usa isso na hora de criar as campanhas
  clientId: 1
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIO_NAME: {
      return produce(state, draft => {
        draft.name = action.data;
      });
    }

    case SET_PORTFOLIO_ID: {
      return produce(state, draft => {
        draft.id = action.data;
      });
    }

    default:
      return state;
  }
};
export default portfolioReducer;
