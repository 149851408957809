import authService from "src/services/authService";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_FIREBASE = "@account/login-firebase";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const REGISTER_REQUEST = "@account/register-request";
export const REGISTER_SUCCESS = "@account/register-success";
export const REGISTER_FAILURE = "@account/register-failure";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const UPDATE_PROFILE = "@account/update-profile";
export const SET_ROLE = "@account/set-role";
export const FORGOT_PASSWORD = "@account/forgot-password";

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      await authService.loginWithEmailAndPassword(email, password);
      // after this src/Firebase::onAuthStateChanged will run and dispatch
      // LOGIN_FIREBASE so there is no need to dispatch anything else here.
    } catch (error) {
      console.error(error)
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function sendEmailForResetPassword(email) {
  return async dispatch => {
      dispatch({ type: FORGOT_PASSWORD });
      await authService.sendEmailWithPassword(email);
  }
}

export function setUserDataFromFirebase(user) {
  return async dispatch => {
    try {
      dispatch({
        type: LOGIN_FIREBASE,
        payload: {
          user
        }
      });

      const response = await authService.backendRequest({
        method: "GET",
        url: `/api/user/${user.id}`
      });
      const userResponse = response.data;
      const fullUser = {
        id: userResponse.uid,
        avatar: userResponse.avatar,
        email: userResponse.email,
        emailVerified: userResponse.emailVerified,
        username: userResponse.displayName,
        phone: userResponse.phoneNumber,
        role: userResponse.customClaims.role
      };
      dispatch(setUserData(fullUser));
    } catch (e) {
      console.error('Dispatch from firebase error', e);
      dispatch(logout());
    }
  }
}

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user
      }
    });
    authService.setLocalUser(user);
  };
}

export function logout() {
  return async dispatch => {
    authService.logout();

     dispatch({
      type: LOGOUT
    });
  };
}

export function register(values) {
  return async dispatch => {
    try {
      console.log("REGISTER_REQUEST");

      dispatch({ type: REGISTER_REQUEST });

      const user = await authService.registerWithEmailAndPassword(values);
      
      dispatch(setUserAdmin(user))
      
      console.log("REGISTER_SUCCESS");
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      console.log("REGISTER FAILURE");
      console.log(error);
      dispatch({ type: REGISTER_FAILURE });
      throw error;
    }
  };
}

export function updateMyProfile(update) {
  return async dispatch => {
    try {
      await authService.backendRequest({
        method: "PATCH",
        url: `/api/user/${update.uid}`,
        data: update
      });
      const response = await authService.backendRequest({
        method: "GET",
        url: `/api/user/${update.uid}`
      });

      const userResponse = response.data;
      const fullUser = {
        id: userResponse.uid,
        avatar: userResponse.avatar,
        email: userResponse.email,
        emailVerified: userResponse.emailVerified,
        username: userResponse.displayName,
        phone: userResponse.phoneNumber,
        role: userResponse.customClaims.role
      };
      dispatch(setUserData(fullUser));
    } catch (e) {
       console.error("update my profile error", e);
    }
  };
}

export function updateOtherProfile(update) {
  return async dispatch => {
    try {
      await authService.backendRequest({
        method: "PATCH",
        url: `/api/user/${update.uid}`,
        data: update
      });

      dispatch({
        type: UPDATE_PROFILE
      });
    } catch (e) {
      console.error("update my profile error", e);
    }
  };
}

export function setUserAdmin(update) {
  return async dispatch => {
    await authService.backendRequest({
      method: "POST",
      url: `/api/user/setrole/${update.uid}/${update.role}`,
      data: update
    });

    dispatch({
      type: SET_ROLE,
    })

  }
}
