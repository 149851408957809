/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from "@material-ui/core";
import {
  // Folder as FolderIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Percent,
  Edit,
  MessageCircle,
  Search,
  Truck,
  DollarSign
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";

const navAdmin = [
  {
    subheader: "Dashboards",
    items: [
      {
        title: "Arquivos Importados",
        icon: PieChartIcon,
        href: "/app/dashboards/arquivos-importados"
      },
      {
        title: "Enriquecimento",
        icon: PieChartIcon,
        href: "/app/dashboards/enriquecimento"
      },
      {
        title: "Operacional",
        icon: PieChartIcon,
        href: "/app/dashboards/operacional"
      }
    ]
  },
  {
    subheader: "Devedores da Nina",
    items: [
      {
        title: "Detalhes sobre o devedor",
        icon: Search,
        href: "/app/management/debtors/0"
      },
      {
        title: "Fale com um Humano",
        icon: MessageCircle,
        href: "/app/chats"
      }
    ]
  },
  {
    subheader: "Gerenciamento",
    items: [
      {
        title: "Usuários",
        icon: UsersIcon,
        href: "/app/management/customers",
        items: [
          {
            title: "Listar Usuários",
            href: "/app/management/customers"
          },
          {
            title: "Criar Usuário",
            href: "/app/management/customers/register"
          }
        ]
      },
      {
        title: "Blacklist",
        icon: UsersIcon,
        href: "/app/management/blacklist",
        items: [
          {
            title: "Listar Usuários",
            href: "/app/management/blacklist"
          },
          {
            title: "Adicionar",
            href: "/app/management/blacklist/add"
          }
        ]
      },
      /* @TODO reativar campaigns no menu
       * quando tiver termniado
       */
      {
        title: "Campanhas",
        icon: UsersIcon,
        href: "/app/management/campaigns",
      },
      /**/
      {
        title: "Descontos",
        icon: Percent,
        href: "/app/management/discounts",
      },
      {
        title: "Envio de cheques",
        icon: Truck,
        href: "/app/management/deliverycheck",
      },
      {
        title: "Editor de diálogo",
        icon: Edit,
        href: "/app/management/dialogeditor",
      },
      {
        title: "Correções monetárias",
        icon: DollarSign,
        href: "/app/management/monetarycorrections",
      },
      // {
      //   title: "Carteira",
      //   icon: FolderIcon,
      //   href: "/app/management/carteira",
      //   items: [
      //     {
      //       title: "Importar Carteira",
      //       href: "/app/management/carteira/import"
      //     },
      //     {
      //       title: "Ver Carteiras",
      //       href: "/app/management/carteira/"
      //     }
      //   ]
      // }
    ]
  }
];

const navClient = [
  {
    subheader: "Dashboards",
    items: [
      {
        title: "Arquivos Importados",
        icon: PieChartIcon,
        href: "/app/dashboards/arquivos-importados"
      },
      {
        title: "Enriquecimento",
        icon: PieChartIcon,
        href: "/app/dashboards/enriquecimento"
      },
      {
        title: "Operacional",
        icon: PieChartIcon,
        href: "/app/dashboards/operacional"
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    zIndex: 0
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  },
  logo: {
    width: "200px"
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const navbarConfig = user.role === "admin" ? navAdmin : navClient;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo avatar={false} className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {navbarConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
