import React from "react";
import PropTypes from "prop-types";

function Logo({ avatar, ...rest }) {
  return (
    <img
      alt="Logo"
      src={avatar === true ? "/static/nina-bg-blue.png" : "/static/AI-Nina-1.png"}
      {...rest}
    />
  );
}

Logo.defaultProps = {
  avatar: true
};

Logo.propTypes = {
  avatar: PropTypes.bool
};

export default Logo;
