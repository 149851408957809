import firebase from "src/FireBase.js";
import axios from "axios";
import moment from "moment";
import store from "src";
import { logout } from "src/actions/accountActions";

class AuthService {
  getLocalToken() {
    // Uncomment to disable the token from localStorage
    // return null;

    try {
      const { token, expire } = JSON.parse(localStorage.getItem("token")) || {};
      if (token !== null && moment().isBefore(expire)) {
        return token;
      }
      return null;
    } catch (e) {
      console.error("Error while parsing token from local storage", e);
      return null;
    }
  }

  setLocalToken(token) {
    const expire = moment()
      .add(50, "minutes")
      .toISOString();
    return localStorage.setItem("token", JSON.stringify({ token, expire }));
  }

  clearLocalData() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }

  getLocalUser() {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch (e) {
      console.error("Erro while parsing user from local storage", e);
      return null;
    }
  }

  setLocalUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getToken = async force => {
    let token = this.getLocalToken();
    if (token) return token;
    try {
      token = await firebase.auth().currentUser.getIdToken(force);
    } catch (e) {
      // Fix cannot call getIdToken() on null
      store.dispatch(logout());
    }
    this.setLocalToken(token);
    return token;
  };

  loginWithEmailAndPassword = async (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  registerWithEmailAndPassword = values =>
    new Promise(async (resolve, reject) => {
      console.log("indo no firebaase");

      const userResponse = await this.backendRequest({
        method: "POST",
        url: "/api/user",
        data: {
          displayName: `${values.firstName} ${values.lastName}`,
          email: values.email,
          password: values.password
        }
      });

      var user = {
        uid: userResponse.data.uid,
        avatar: userResponse.data.photoUrl,
        email: userResponse.data.email,
        emailVerified: userResponse.data.emailVerified,
        username: userResponse.data.displayName,
        phone: userResponse.data.phoneNumber,
        role: values.role
      };

      //isso é como se retorna o objeto numa promise
      resolve(user);
    });

  logout = () => {
    this.clearLocalData();
    firebase
      .auth()
      .signOut()
      .then(function() {})
      .catch(function(error) {
        // An error happened.
      });
  };

  sendEmailWithPassword = email =>
    firebase.auth().sendPasswordResetEmail(email);

  verifyCodeForResetPassword = (actionCode, newPassword) =>
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(function(email) {
        firebase
          .auth()
          .confirmPasswordReset(actionCode, newPassword)
          .then(function(resp) {})
          .catch(function(error) {
            return error;
          });
      })
      .catch(function(error) {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        throw error;
      });

  backendRequest = async ({ url, method, data, headers }) => {
    try {
      const token = await this.getToken();
      const authHeaders = { Authorization: `Bearer ${token}`, ...headers };
      const response = await axios({
        url,
        method,
        data,
        headers: authHeaders
      });
      if (response.status === 401) {
        const newToken = await this.getToken(true); // force renew
        const newAuthHeaders = {
          Authorization: `Bearer ${newToken}`,
          ...headers
        };
        return axios({
          url,
          method,
          data,
          headers: newAuthHeaders
        });
      }
      return response;
    } catch (e) {
      console.error("Backend request error", e);
      throw e;
    }
  };

  // Same as above but withtout excetpions
  tryBackendRequest = async ({ url, method, data, headers }) => {
    try {
      const response = await this.backendRequest({
        url,
        method,
        data,
        headers
      });
      return {
        success: true,
        response
      };
    } catch (error) {
      return {
        success: false,
        error
      };
    }
  };
}

const authService = new AuthService();

export default authService;
