/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthClientGuard from "src/components/AuthClientGuard";
import AuthAdminGuard from "src/components/AuthAdminGuard";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/pages/Error404View"))
  },
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    exact: true,
    path: "/forgot-password",
    component: lazy(() => import("src/views/auth/ForgotPasswordView"))
  },
  {
    exact: true,
    path: "/change-password",
    component: lazy(() => import("src/views/auth/ResetPasswordView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/customers/register",
    component: lazy(() => import("src/views/management/RegisterNewUserView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/reports/dados-do-devedor",
    component: lazy(() => import("src/views/reports/DadosDoDevedor"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/nina-debtors",
    component: lazy(() => import("src/views/management/NinaDebtorsListView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/customers",
    component: lazy(() => import("src/views/management/CustomerListView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/customers/:customerId",
    component: lazy(() => import("src/views/management/CustomerDetailsView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/customers/:customerId/edit",
    component: lazy(() => import("src/views/management/CustomerEditView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/debtors/:debtorDoc",
    component: lazy(() => import("src/views/management/DebtorDetailsView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/carteira/import",
    component: lazy(() => import("src/views/management/CarteiraUploadView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/blacklist",
    component: lazy(() => import("src/views/management/BlacklistView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/blacklist/add",
    component: lazy(() => import("src/views/management/BlacklistAddView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/discounts",
    component: lazy(() => import("src/views/management/DiscountsView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/campaigns",
    component: lazy(() => import("src/views/management/CampaignsView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/deliverycheck",
    component: lazy(() => import("src/views/management/DeliveryCheckView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management",
    component: () => <Redirect to="/app/management/customers" />
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/dialogeditor",
    component: lazy(() => import("src/views/management/DialogEditorView"))
  },
  {
    exact: true,
    guard: AuthAdminGuard,
    layout: DashboardLayout,
    path: "/app/management/monetarycorrections",
    component: lazy(() => import("src/views/management/MonetaryCorrectionsView"))
  },
  {
    path: "/app",
    guard: AuthClientGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/dashboards/arquivos-importados" />
      },
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/pages/AccountView"))
      },
      {
        exact: true,
        path: "/app/chats",
        component: lazy(() => import("src/views/pages/ChatsView"))
      },
      {
        exact: true,
        path: "/app/dashboards/arquivos-importados",
        component: lazy(() => import("src/views/reports/ArquivosImportados"))
      },
      {
        exact: true,
        path: "/app/dashboards/enriquecimento",
        component: lazy(() => import("src/views/reports/Enriquecimento"))
      },
      {
        exact: true,
        path: "/app/dashboards/operacional",
        component: lazy(() => import("src/views/reports/Operacional"))
      },
      {
        exact: true,
        path: "/app/reports",
        component: () => <Redirect to="/app/dashboards/enriquecimento" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
