import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingScreen from "src/components/LoadingScreen";

function AuthClientGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (account.user === null) {
    return <LoadingScreen/>;
  }

  if (account.user === false) {
    return <Redirect to="/login"/>;
  }


  return children;
}

AuthClientGuard.propTypes = {
  children: PropTypes.any
};

export default AuthClientGuard;
