import React, { useState } from "react";
import PropTypes from "prop-types";
import SplashScreen from "src/components/SplashScreen";

function Auth({ children }) {
  const user = useState("account.user");

  if (!user) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
