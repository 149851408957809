/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  LOGIN_REQUEST,
  LOGIN_FIREBASE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
  FORGOT_PASSWORD,
  UPDATE_PROFILE,
  SET_ROLE
} from "src/actions/accountActions";

import * as _merge from "lodash/merge";

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_FIREBASE: {
      // Since firebase return less data than backend
      // and state account.user maybe polulated from
      // local storage, we need to merge the data
      // retrieved from firebase with what we already
      // have on state. Otherwise the role (for example)
      // that is null from Firebase, may be set to null
      // on state, breaking admin pages on reload
      const { user } = action.payload;
      const newUser = _merge(state.user, user);
      return produce(state, draft => {
        draft.user = newUser;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;
      return produce(state, draft => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, draft => {
        draft.user = false;
      });
    }

    case FORGOT_PASSWORD: {
      return produce(state, draft => {
        // Ensure we clear current session
      });
    }

    case REGISTER_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
      });
    }

    case REGISTER_SUCCESS: {
      return produce(state, draft => {});
    }

    case REGISTER_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SET_ROLE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = false;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
