// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;
export const FIREBASE_SETTINGS = {
  APP: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    // clientId é encontrado aqui: https://console.firebase.google.com/project/nina-yucs/authentication/providers
    clientId: process.env.REACT_APP_FIREBASE_CLIENT_ID,
  },
  ENV: process.env.NODE_ENV == 'development' ? 'dev' : 'prod'
};

export const NINA = {
  HUMAN_KEY: '&nina-human',
  BOT_KEY: '&nina-bot'
}

export default {};
