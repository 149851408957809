import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingScreen from "src/components/LoadingScreen";

function AuthAdminGuard({ children }) {
  const account = useSelector(state => state.account);

  if (account.user === false) {
    return <Redirect to="/login" />;
  }

  if (account.user === null || !account.user.role) {
    return <LoadingScreen />;
  }

  if (account.user.role !== "admin") {
    return <Redirect to="/404" />;
  }

  return children;
}

AuthAdminGuard.propTypes = {
  children: PropTypes.any
};

export default AuthAdminGuard;
