import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import portfolioReducer from "./portfolioReducer";
import notificationsReducer from "./notificationsReducer";

const rootReducer = combineReducers({
  portfolio: portfolioReducer,
  account: accountReducer,
  notifications: notificationsReducer,
  form: formReducer
});

export default rootReducer;
