import firebase from "firebase/app";
import store from "src";
import * as actions from "src/actions/accountActions";
import { FIREBASE_SETTINGS } from 'src/config'

import "firebase/auth";
import 'firebase/database'
import 'firebase/storage'

firebase.initializeApp(FIREBASE_SETTINGS.APP);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().onAuthStateChanged(function (userResponse) {
  if (userResponse) {
    //online
    const user = {
      id: userResponse.uid,
      avatar: userResponse.avatar,
      email: userResponse.email,
      emailVerified: userResponse.emailVerified,
      username: userResponse.displayName,
      phone: userResponse.phoneNumber
    };

    // it seems that the token is not generated before entering here,
    // this temporarily resolves
    setTimeout(() => {
      store.dispatch(actions.setUserDataFromFirebase(user));
    }, 0)
  } else {
    //offline
    store.dispatch(actions.logout());
  }
});

//exporta como um singleton para nao inicializar o firebase novamente
export default !firebase.apps.length
  ? firebase.initializeApp(FIREBASE_SETTINGS.APP)
  : firebase.app();

export {
  firebase
}